<template>
    <div class="kickoff">
        <main>
            <div class="container">
                <div class="header">
                    <div class="apl-presents">
                        <img src="https://cms.aplesports.com/storage/uploads/2020/05/29/5ed1243dd38ebAPL-Presents.png" alt="APL Presents" width="100%">
                    </div>
                    <div class="sponsored-by-psyonix">
                        <img src="https://cms.aplesports.com/storage/uploads/2020/06/03/5ed7144204020Sponsored_By_Psyonix.png" alt="APL Presents" width="100%">
                    </div>
                </div>
                <div class="kickoff-logo">
                    <img src="https://cms.aplesports.com/storage/uploads/2020/05/29/5ed13402b4f69The-Kickoff-with-Shadow.png" alt="The Kickoff Logo" width="100%">
                </div>
                <div class="scroll">
                    <a href="#details">
                        <span></span>
                    </a>
                </div>
            </div>
        </main>

        <section class="details" id="details">
            <div data-aos="fade-in" data-aos-id="countUp" class="counter">
                <h1>
                    Prize Pool: $<span class="pool"></span>
                </h1>
            </div>

            <div class="subt middle-east">
                <div class="container">
                    <div class="logo" data-aos="fade-in" data-aos-delay="400">
                        <img src="https://cms.aplesports.com/storage/uploads/2020/06/03/5ed71719cea31The_Kickoff_ME_with_Shadow.png" alt="The Kickoff - Middle East Logo">
                    </div>
                    <div class="info">
                        <ul data-aos="fade-in" data-aos-delay="400">
                            <li class="big">Finals: July 11th - 6pm AST (Arabian Standard Time) | 4pm BST | 11am EDT | 8am PDT</li>
                            <li>Qualifier 1: June 27th - 5pm AST | 3pm BST | 10am EDT | 7am PDT</li>
                            <li>Qualifier 2: July 4th - 5pm AST | 3pm BST | 10am EDT | 7am PDT</li>
                            <li>Prize Pool: $22,750</li>
							<a href="https://cms.aplesports.com/storage/uploads/2020/06/23/5ef1e196bd2bdThe-Kickoff---Official-RulesetV1.3.pdf" target="_blank" class="btn">Rulebook</a>
							<a href="https://register.aplesports.com" target="_blank" class="btn" style="margin-top: 1rem">Register</a>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="subt asia">
                <div class="container">
                    <div class="info">
                        <ul data-aos="fade-in" data-aos-delay="400">
                            <li class="big">Finals: July 12th - 6pm JST | 10am BST | 5am EDT | 2am PDT</li>
                            <li>Qualifier 1: June 28th - 5pm JST | 9am BST | 4am EDT | 3am PDT</li>
                            <li>Qualifier 2: July 5th - 5pm JST | 9am BST | 4am EDT | 3am PDT</li>
                            <li>Prize Pool: $22,750</li>
							<a href="https://cms.aplesports.com/storage/uploads/2020/06/03/5ed7cd81c0054The-Kickoff---Official-Ruleset.pdf" target="_blank" class="btn">Rulebook</a>
							<a href="https://register.aplesports.com" target="_blank" class="btn" style="margin-top: 1rem">Register</a>
                        </ul>
                    </div>
                    <div class="logo" data-aos="fade-in" data-aos-delay="400">
                        <img src="https://cms.aplesports.com/storage/uploads/2020/06/03/5ed7171fe58d4The_Kickoff_Asia_with_Shadow.png" alt="The Kickoff - Asia Logo">
                    </div>
                </div>
            </div>

            <div class="trailer" data-aos="fade-in" data-aos-anchor-placement="top-center">
                <iframe src="https://www.youtube.com/embed/t4yxMOYvFRw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>

            <div class="footer">
                Rocket League, Psyonix, and all related marks and logos are trademarks or registered trademarks of Psyonix LLC and used under license.  © 2015-2020 Licensed by Psyonix LLC, All rights reserved. All other trademarks are property of their respective owners.
            </div>
        </section>

    </div>
</template>

<script>
import { CountUp } from 'countup.js';

export default {
    name: 'Kickoff',
    created() {
        document.addEventListener('aos:in:countUp', () => {
            const options = {
              duration: 1.2,
            };
            let el = document.querySelector('.pool');
            let demo = new CountUp(el, 45500, options);
            if (!demo.error) {
              demo.start();
            } else {
              console.error(demo.error);
            }
        });
    },
    methods: {
        count() {
            const options = {
              duration: 1.2,
            };

            let demo = new CountUp('myTargetElement', 45500, options);

            if (!demo.error) {
              demo.start();
            } else {
              console.error(demo.error);
            }
        }
    }
}
</script>
