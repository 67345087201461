<template>
    <div class="tournaments">
        <main>
            <div class="overlay"></div>
            <img src="https://cms.aplesports.com/storage/uploads/2020/05/06/5eb29cbc2fea41920x400_3.png">
            <div class="container">
                <div class="title main">tournaments</div>
            </div>
        </main>

        <section class="grid">
            <div class="container">    
                <div class="vertical-card" v-for="t in display" :key="t._id">
                    <div class="card__header">
                        <div class="card__header__img"><img :src=" imgRoot + t.logo.path" alt=" "></div>
                        <div class="card__header__title">{{ t.name }}</div>
                        <div class="card__header__date">
                            {{ t.date }}
                        </div>
                    </div>
                    <div class="card__body">
                        <p>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"/></svg>
                            {{ t.time }}
                        </p>
                        <p>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm1-5h1a3 3 0 0 0 0-6H7.99a1 1 0 0 1 0-2H14V5h-3V3H9v2H8a3 3 0 1 0 0 6h4a1 1 0 1 1 0 2H6v2h3v2h2v-2z"/></svg>
                            {{ t.prize }}
                        </p>
                        <div v-if="t.link" class="card__body__cta">
                            <a :href="t.link" target="_blank">Details</a>
                        </div>
                    </div>
                    <div class="card__arrow">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><polygon points="9 16.172 2.929 10.101 1.515 11.515 10 20 10.707 19.293 18.485 11.515 17.071 10.101 11 16.172 11 0 9 0"/></svg>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Tournaments',
    data() {
        return {
            tournaments: {},
            display: {},
            count: 9,
            bottom: false,
            total: null,
            imgRoot: 'https://cms.aplesports.com'
        }
    },
    created() {
        axios.post("https://cms.aplesports.com/api/collections/get/Tournaments", {
            "sort": {
                "_created": -1
            },
            "fields": {
                "name": 1,
                "logo": 1,
                "time": 1,
                "date": 1,
                "prize": 1,
                "link": 1
            }
            } ,{
            headers: {
                'Cockpit-Token': process.env.VUE_APP_CMS_TOKEN
            }
        })
        .then((res) => {
            res.data.entries.forEach((t) => {
                let d = Date.parse(t.date);
				try {
	                const dtf = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'short', day: '2-digit' });
    	            const [{ value: mo },,{ value: da },,{ value: ye }] = dtf.formatToParts(d);
	                t.date = `${da} ${mo}, ${ye}`;
				} catch($err) {
					console.warn(`Warning: Date conversion error for ${t.name}`);
				}
            });

            this.tournaments = res.data.entries;
            this.display = this.tournaments.slice(0, this.count);
            this.total = res.data.total;
        });
        window.addEventListener('scroll', () => {
            this.bottom = this.bottomVisible();
        });
    },
    methods: {
        bottomVisible() {
            return (document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight ? true : false);
        },
        addTournaments() {
            if(this.display.length == this.tournaments.length) {
                return false;
            }
            let n = this.tournaments.slice(this.count, this.count + 3);
            this.display.push(n[0]);
            this.display.push(n[1]);
            this.display.push(n[2]);
            this.count += 3;
        }
    },
    watch: {
        bottom(bottom) {
            if(bottom) {
                this.addTournaments();
            }
        }
    }
}
</script>
